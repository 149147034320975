<template>
  <div class="container">
    <div class="section">
      <h1 class="title">Server settings</h1>
      <hr />

      <form @submit.prevent="submit" class="form">
        <div class="field">
          <label class="label" for="mmApiUrl">Mattermost API URL</label>
          <div class="control">
            <input
              class="input"
              id="mmApiUrl"
              type="url"
              placeholder="URL"
              v-model="mattermostApiUrl"
              :disabled="isLoading"
              required
            />
          </div>
          <p class="help">eg. https://mattermost.server/api/v4</p>
        </div>

        <div class="field mt-5">
          <label class="label" for="mmApiAccessToken">
            Mattermost API access token
          </label>
          <div class="control">
            <input
              class="input"
              id="mmApiAccessToken"
              type="password"
              placeholder="Access token"
              v-model="mattermostApiAccessToken"
              :disabled="isLoading"
              minlength="26"
              maxlength="26"
              required
            />
          </div>
        </div>

        <div class="field mt-5">
          <label class="label" for="mmBotId">Mattermost Bot ID</label>
          <div class="control">
            <input
              class="input"
              id="mmBotId"
              type="text"
              placeholder="Bot ID"
              v-model="mattermostBotId"
              :disabled="isLoading"
              minlength="26"
              maxlength="26"
              required
            />
          </div>
        </div>

        <div class="field mt-5">
          <label class="label" for="mmInfoChannelId">
            Mattermost info channel ID
          </label>
          <div class="control">
            <input
              class="input"
              id="mmInfoChannelId"
              type="text"
              placeholder="Info channel ID"
              v-model="mattermostInfoChannelId"
              :disabled="isLoading"
              minlength="26"
              maxlength="26"
              required
            />
          </div>
        </div>

        <div class="field mt-5">
          <label class="label" for="mmDailyChannelId">
            Mattermost daily channel ID
          </label>
          <div class="control">
            <input
              class="input"
              id="mmDailyChannelId"
              type="text"
              placeholder="Info channel ID"
              v-model="mattermostDailyChannelId"
              :disabled="isLoading"
              minlength="26"
              maxlength="26"
              required
            />
          </div>
        </div>

        <div class="field mt-5">
          <label class="label" for="webAppUrl"> Web app URL </label>
          <div class="control">
            <input
              class="input"
              id="webAppUrl"
              type="url"
              v-model="webAppUrl"
              :disabled="isLoading"
              placeholder="Web app URL"
              required
            />
          </div>
        </div>

        <div class="field mt-5">
          <p class="control">
            <button
              class="button is-link"
              :class="{ 'is-loading': isUpdating }"
            >
              Save
            </button>
          </p>
        </div>
      </form>

      <hr />

      <div class="title mt-6">Daily status</div>
      <button class="button" @click="sendDailyStatus">Send daily status</button>
    </div>
  </div>
</template>

<script lang="ts">
import api, { useApi } from '@/api'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    const mattermostApiUrl = ref('')
    const mattermostApiAccessToken = ref('')
    const mattermostBotId = ref('')
    const mattermostInfoChannelId = ref('')
    const mattermostDailyChannelId = ref('')
    const webAppUrl = ref('')

    const isLoading = ref(true)
    const settingsRes = useApi(api => api.settings.get())

    watch(settingsRes.data, data => {
      mattermostApiUrl.value = data?.mattermostApiUrl || ''
      mattermostApiAccessToken.value = data?.mattermostApiAccessToken || ''
      mattermostBotId.value = data?.mattermostBotId || ''
      mattermostInfoChannelId.value = data?.mattermostInfoChannelId || ''
      mattermostDailyChannelId.value = data?.mattermostDailyChannelId || ''
      webAppUrl.value = data?.webAppUrl || ''
      isLoading.value = false
    })

    const isUpdating = ref(false)
    async function submit() {
      if (isLoading.value === true || isUpdating.value === true) return
      isUpdating.value = true
      const res = await api.settings.update({
        mattermostApiUrl: mattermostApiUrl.value,
        mattermostApiAccessToken: mattermostApiAccessToken.value,
        mattermostBotId: mattermostBotId.value,
        mattermostInfoChannelId: mattermostInfoChannelId.value,
        mattermostDailyChannelId: mattermostDailyChannelId.value,
        webAppUrl: webAppUrl.value
      })

      settingsRes.data.value = res.data
      isUpdating.value = false
    }

    function sendDailyStatus() {
      api.timeline.dailyStatusTestMessage()
    }

    return {
      sendDailyStatus,
      mattermostApiUrl,
      mattermostApiAccessToken,
      mattermostBotId,
      mattermostInfoChannelId,
      mattermostDailyChannelId,
      webAppUrl,
      submit,
      isLoading,
      isUpdating
    }
  }
})
</script>

<style lang="scss" scoped>
.form {
  max-width: 400px;
}
</style>
