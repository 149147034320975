
import api, { useApi } from '@/api'
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  setup() {
    const mattermostApiUrl = ref('')
    const mattermostApiAccessToken = ref('')
    const mattermostBotId = ref('')
    const mattermostInfoChannelId = ref('')
    const mattermostDailyChannelId = ref('')
    const webAppUrl = ref('')

    const isLoading = ref(true)
    const settingsRes = useApi(api => api.settings.get())

    watch(settingsRes.data, data => {
      mattermostApiUrl.value = data?.mattermostApiUrl || ''
      mattermostApiAccessToken.value = data?.mattermostApiAccessToken || ''
      mattermostBotId.value = data?.mattermostBotId || ''
      mattermostInfoChannelId.value = data?.mattermostInfoChannelId || ''
      mattermostDailyChannelId.value = data?.mattermostDailyChannelId || ''
      webAppUrl.value = data?.webAppUrl || ''
      isLoading.value = false
    })

    const isUpdating = ref(false)
    async function submit() {
      if (isLoading.value === true || isUpdating.value === true) return
      isUpdating.value = true
      const res = await api.settings.update({
        mattermostApiUrl: mattermostApiUrl.value,
        mattermostApiAccessToken: mattermostApiAccessToken.value,
        mattermostBotId: mattermostBotId.value,
        mattermostInfoChannelId: mattermostInfoChannelId.value,
        mattermostDailyChannelId: mattermostDailyChannelId.value,
        webAppUrl: webAppUrl.value
      })

      settingsRes.data.value = res.data
      isUpdating.value = false
    }

    function sendDailyStatus() {
      api.timeline.dailyStatusTestMessage()
    }

    return {
      sendDailyStatus,
      mattermostApiUrl,
      mattermostApiAccessToken,
      mattermostBotId,
      mattermostInfoChannelId,
      mattermostDailyChannelId,
      webAppUrl,
      submit,
      isLoading,
      isUpdating
    }
  }
})
